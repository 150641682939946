.submenu {
  margin-left: 50px;
  transition: all 0.3s ease;
}

.submenu.show {
  opacity: 1;
  max-height: 1000px;
}
.h-screen-adjusted {
  height: calc(var(--vh, 1vh) * 100);
}
