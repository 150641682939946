@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'poppins';
  src: url('./fonts/Poppins-Regular.ttf');
}

body {
  @apply font-poppins bg-[#fff];
}

.about_main {
  @apply bg-primary;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.horizontal-steps .ant-steps-item-finish .ant-steps-item-icon .anticon-check {
  color: white !important; /* Replace with your desired color */
}
/* Change the color of the steps circles */
.horizontal-steps .ant-steps-item-process .ant-steps-item-icon,
.horizontal-steps .ant-steps-item-finish .ant-steps-item-icon,
.horizontal-steps .ant-steps-item-error .ant-steps-item-icon {
  background-color: #144618 !important; /* Replace with your desired color */
  border-color: #144618 !important; /* Replace with your desired color */
}

.horizontal-steps .ant-steps-item-finish .ant-steps-item-title::after {
  background-color: #144618 !important; /* Replace with your desired color */
  color: #144618 !important; /* Replace with your desired color */
}
.email-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1023px) {
  .email-wrapper {
    width: 100%;
  }
}
.input-cover {
  display: flex;
  width: 100%;
  position: relative;

  gap: 0.5rem; /* 0.5rem is equivalent to 8px for gap-2 */

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.jj {
  appearance: none;
  width: 22px;
  height: 22px;
  background-color: #d9d9d9; /* light green */
  border-radius: 100%;
  border: none;
  cursor: pointer;
  position: relative;
}
input[type='checkbox'] {
  appearance: none;
  width: 22px;
  height: 22px;
  background-color: #d9d9d9; /* light green */
  border-radius: 100%;
  border: none;
  cursor: pointer;
  position: relative;
}
input[type='checkbox']:checked::after {
  content: '\2713';
  color: #ffffff; /* change this to the color you want */
  font-size: 15px;
  font-weight: bold; /* make the checkmark bolder */
  font-family: Arial; /* change the font family */
  background-color: #46ca08; /* add a background color to the checkmark */
  border-radius: 100%; /* make the checkmark circular */
  padding: 2px; /* add some padding to the checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-select-container .ant-select .ant-select-selector {
  border-radius: 20px;
  border-color: #144618;
}

.my-select-container .ant-select .ant-select-selector:hover {
  border-color: #2ea043; /* new border color on hover */
}
.borderedSelect {
  border-radius: 10px !important;
  background-color: white;
  border: 1px solid darkgray;

  &:hover {
    border-color: #356796 !important;
    border-top-color: rgb(53, 103, 150);
    border-right-color: rgb(53, 103, 150);
    border-bottom-color: rgb(53, 103, 150);
    border-left-color: rgb(53, 103, 150);
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f3f5f6;
}
.empty-content {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.show-btn {
  color: #087867;
  font-size: 0.875rem; /* 14px */
  position: absolute;
  top: 10px;
  right: -15px;
  width: 60px;
}
.checkbox-wrapper {
  width: 100%;
  display: flex;
  gap: 0.75rem; /* 12px */
  justify-content: flex-start;
  color: #6c737f;
  font-size: 1rem; /* 16px */
  align-items: center;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #F8F8F8 inset !important;
}
/* Styling focus state for <select> as an alternative */
select:focus {
  background-color: #F8F8F8 ;
  border: none;
  outline: none;
}
.show-btn {
  color: #087867;
  font-size: 0.875rem; /* 14px */
  position: absolute;
  top: 10px;
  right: -15px;
  width: 60px;
}
.checkbox-wrapper {
  width: 100%;
  display: flex;
  gap: 0.75rem; /* 12px */
  justify-content: flex-start;
  color: #6c737f;
  font-size: 1rem; /* 16px */
  align-items: center;
}

.show-btn {
  color: #087867;
  font-size: 0.875rem; /* 14px */
  position: absolute;
  top: 10px;
  right: -15px;
  width: 60px;
}
.checkbox-wrapper {
  width: 100%;
  display: flex;
  gap: 0.75rem; /* 12px */
  justify-content: flex-start;
  color: #6c737f;
  font-size: 1rem; /* 16px */
  align-items: center;
}

.chart-container {
  width: 100%;
  height: 100%;
}

.chart-cover {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.seperator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.chart {
  width: 100% !important;
  height: auto !important;
}

.dropdown-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.dropdown-content.open {
  max-height: 200px; /* Adjust based on your content height */
  opacity: 1;
}
.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #DDDDDD;
  color: black
}
/* Remove the arrow number selectors (spinners) in Webkit browsers like Chrome and Edge */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove the spinners in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* global.css or main CSS file */
::-webkit-scrollbar {
  display: 8px; /* Chrome, Safari, and Opera */
}


* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: 8px; /* Firefox */
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="url"] {
  autocomplete: off;
}
@layer utilities {
  .center {
    @apply flex items-center justify-center;
  }

  .start {
    @apply flex justify-start items-center;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
  .activeplan {
    @apply border border-darkgreen bg-darkGreen flex flex-col  justify-center w-full h-[120px] rounded-md  p-[8%] lg:p-[5%] lg:space-y-3 space-y-2 text-white cursor-pointer;
  }
  .unactiveplan {
    @apply w-full flex flex-col  justify-center  h-[120px] bg-lightash p-[8%] rounded-md lg:p-[5%] lg:space-y-3 space-y-2 text-black cursor-pointer;
  }
  .btnn {
    @apply inline-flex items-center bg-darkgreen text-white justify-center whitespace-nowrap rounded-md text-sm w-full h-[48px] font-medium;
  }
  .plancover {
    @apply w-full flex-col gap-8  flex center mt-[2%];
  }
  .login-text {
    @apply w-full center text-sm lg:text-base text-black;
  }
  .login-link {
    @apply text-darkgreen underline;
    padding: 0 5px;
  }
  .left-content {
    @apply lg:flex  justify-center items-center w-full lg:w-[50%] h-[30vh] lg:h-screen  relative;
  }
  .right-content {
    @apply w-full lg:w-[50%] h-full  p-[3%] z-40 bg-white rounded-t-[50px];
  }
  .header-text {
    @apply lg:text-3xl text-lg text-darkgreen font-semibold;
  }
  .wrapper {
    @apply w-full lg:p-0 h-full  flex justify-center items-start ;
  }
  .image-text-wrapper {
    @apply w-full absolute bottom-[7%] left-[5%] text-white space-y-2 lg:space-y-4 flex-col lg:justify-start lg:items-start lg:flex lg:text-left;
  }
  .content-cover {
    @apply w-full  h-full p-[3%];
  }
  .input-style {
    @apply bg-[#F3F5F6] h-[48px] flex w-full  rounded-md border-gray-300 px-3 py-2 text-sm ring-offset-darkgreen file:border-0 file:bg-darkgreen file:text-sm file:font-medium  focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50;
  }
  .form-wrapper {
    @apply mt-[8%] w-full lg:w-[90%] lg:h-full h-[40vh]  space-y-6;
  }
  .stepstyle {
    @apply lg:w-[50%] flex w-[70%] mx-auto lg:mx-0;
  }
  .notactive {
    @apply h-[50px] rounded-[8px] bg-darkash text-black;
  }
  .job-wrapper {
    @apply w-full center m-auto h-screen space-y-5 flex-col text-center;
  }
  .link {
    @apply text-darkgreen underline font-bold text-sm;
  }
  .sidebar-wrapper {
    @apply w-[18%]  fixed bg-darkgreen  lg:flex flex-col justify-between items-center h-screen  shadow-lg hidden py-3;
  }
  .top-content-wrapper {
    @apply w-full flex flex-col justify-center items-start px-3;
  }
  .logo-cover {
    @apply w-full flex justify-start items-center pl-10;
  }
  .logout-button-style {
    @apply center text-white gap-2  w-[80%] mx-auto rounded-md p-3 ml-3;
  }
  .topbar-wrapper {
    @apply w-full flex flex-col gap-1  lg:pl-[18%] pl-0;
  }
  .link-container {
    @apply w-full flex gap-6 capitalize text-gray-600 justify-start  text-sm  h-[45px]  items-center;
  }
  .link-wrapper {
    @apply w-full flex-col center mt-[5%] pl-3;
  }
  .bell-style {
    @apply bg-[#ADEE68] text-black rounded-full p-1 w-[30px] h-[30px] lg:w-[38px] lg:h-[38px] center;
  }
  .topnav-container {
    @apply w-full mt-2 lg:mt-0 flex justify-between items-center py-3 border-b px-[2%];
  }
  .topnav-content-style {
    @apply lg:w-full flex justify-end items-end gap-2;
  }
  .between {
    @apply w-full justify-between items-center flex;
  }
  .header-cover {
    @apply w-full flex-col justify-between items-start gap-2 lg:items-center lg:flex-row flex;
  }
  .add-farm {
    @apply center gap-2 w-full lg:w-[260px] bg-[#ADEE68] text-darkGreen h-[45px] rounded-md text-sm p-3 font-semibold;
  }
  .weather-cover {
    @apply w-full h-[150px] relative text-white text-sm;
  }
  .weather-img {
    @apply w-full absolute h-full object-cover rounded-md;
  }
  .weathercontent {
    @apply flex justify-between items-baseline w-full absolute bottom-0 px-2 lg:px-5 py-3;
  }
  .chart-cover {
    @apply w-full bg-[#F9F9F9] rounded-md h-[500px] px-[2%] py-[1%];
  }
  .seperator {
    @apply flex justify-between items-baseline w-full text-black font-semibold;
  }
  .filter-btn {
    @apply m-0 p-0 w-[400px] flex justify-end items-center gap-2 capitalize;
  }
  .drop-item {
    @apply bg-[#F1F1F1] z-[1] w-40 lg:w-52 absolute left-[-25px] lg:left-[-80px] p-0 shadow;
  }
  .drop-content {
    @apply w-full  rounded-t-lg border-none shadow-none flex justify-between  items-center hover:bg-[#E9E9E9] relative;
  }
  .aside {
    @apply bg-[#F1F1F1]  z-[1] left-[-220px] w-52 absolute top-0 p-2;
  }
  .fill {
    @apply border-none   w-[130px] relative text-sm lg:text-base;
  }
  .farmwrapper {
    @apply w-full h-full  bg-white p-[3%] lg:p-[2%] space-y-5 flex flex-col;
  }

  .separator {
    @apply flex justify-between items-baseline w-full text-black font-semibold;
  }
  .sort-hover {
    @apply p-3 h-[45px] hover:bg-[#E9E9E9] flex justify-start items-center;
  }
  .sort {
    @apply z-[1] right-[-15px] w-[150px] py-2 bg-[#F1F1F1];
  }
  .number {
    @apply size-8 center rounded  text-center leading-8 text-black font-semibold;
  }
  .number-green {
    @apply size-8 center rounded  bg-darkgreen text-center leading-8 text-white font-semibold;
  }
  .dialog {
    @apply w-full flex justify-end items-center flex-col  h-full  rounded-md;
  }
  .b-none {
    @apply w-full border-none shadow-none  flex justify-end items-center p-0 outline-none;
  }
  .btn-style {
    @apply  w-[70%] mx-auto mt-3 bg-darkGreen text-white hover:bg-darkGreen;
  }
  .pag-cover {
    @apply flex justify-start items-center  gap-1 text-xs font-medium mt-2 w-[170px] lg:w-[300px];
  }
  .activebutton {
    @apply bg-darkGreen text-white hover:bg-darkGreen h-[50px] w-full rounded-[8px];
  }
  .cheveron-wrapper {
    @apply w-[70%] lg:w-[60%] flex justify-between items-center text-darkgreen text-xl lg:text-2xl font-semibold;
  }
  .form-payment {
    @apply w-full lg:w-[90%] h-[60vh] lg:h-full space-y-7 mt-[8%]  mx-auto text-xs lg:text-base;
  }
  .ts {
    @apply text-[13px] md:text-lg;
  }
  .error-text {
    @apply text-red-500 text-xs ;
  }
}
