@tailwind base;
@tailwind components;
@tailwind utilities;

.trending-news-container {
    @apply overflow-x-auto whitespace-nowrap sm:overflow-x-auto sm:whitespace-nowrap;
}

.circle {
    @apply rounded-full w-3 h-3 bg-darkGreen;
}
.card-wrapper {
    @apply w-full mt-4 flex flex-wrap justify-start gap-2 items-center;
}
.cover {
    @apply w-[50%] lg:w-[24%] bg-[#f9f9f9] rounded-lg p-3 space-y-3  flex flex-col;
}