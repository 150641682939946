.bounce{
  animation: move 3s infinite ease alternate;
}
@keyframes move {
  from{
    transform: translateY(-20px);
  }
  to{
    transform: translateY(5px);
  }
}

.products_hero {
  @apply flex flex-col gap-5 lg:gap-10 lg:py-[72px];
}

.products_pricing {
  @apply flex flex-col gap-[20px];
}

.pricing_headers {
  @apply flex flex-col lg:flex-row justify-between  items-center lg:bg-[url('../../assets/pricing_bg.png')] w-full h-auto bg-cover bg-center lg:pr-[140px] p-2 lg:pl-20 lg:py-[30px];
}

.pricing-header_content {
  @apply flex flex-col flex-[0.6] gap-4 lg:gap-[35px];
}

.pricing-header_button {
  @apply flex w-[100px] cursor-pointer p-1 lg:w-[226px] lg:py-4 lg:px-5 justify-center items-center bg-white rounded-lg;
}
.pricing-header_button_mobile {
  @apply flex w-full cursor-pointer p-1 lg:w-[226px] lg:py-4 lg:px-5 justify-center items-center bg-white rounded-lg;
}
.pricimg-header_amount {
  @apply flex flex-col flex-[0.4] lg:items-end lg:justify-end lg:gap-[15px] text-primary text-[16px] lg:text-[28px] font-[600] text-end lg:text-center;
}

.plans {
  @apply flex flex-col gap-5 lg:gap-[60px];
}

.pricing-content {
  @apply flex flex-col gap-10 p-5 lg:px-20 lg:py-[60px];
}
